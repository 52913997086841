/* .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content1,
.modal-content2 {
  padding: 20px;
  border-radius: 8px;
  color: white;
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.modal-content1 {
  background-color: #2b2b2b;
  animation: zoomIn 0.7s ease-in-out;
}

.modal-content2 {
  background-color: #343a40;
  animation: bounceIn 0.8s ease-in-out;
}

.modal-header1,
.modal-header2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.close:hover {
  color: #ff6b6b;
}

.welcome-title {
  font-size: 1.8rem;
}

.ace-title {
  font-size: 2rem;
  color: #ff6b6b;
}

.modal-body {
  padding: 1.5rem;
  font-size: 1.05rem;
  color: #ddd;
}

.modal-footer1,
.modal-footer2 {
  margin-top: 1.5rem;
  font-size: 1.2rem;
}

.content-container {
  background-color: #1e1e1e;
  color: #fff;
  min-height: 100vh;
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  20% {
    transform: translate3d(0, 25px, 0);
  }
  40% {
    transform: translate3d(0, -10px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 5px, 0);
  }
  80% {
    transform: translate3d(0, -2px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
} */

#Termandcondition {
  color: white !important;
  background-color: #0092ad !important;
  text-align: start !important;
  padding: 10px 20px !important;
  font-weight: 400 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  font-size: 16px !important;
}
/* 
#hindi {
  background-color: #0092ad !important;
  color: white;
  border: 1px solid #0092ad;
  border-radius: 5px;
  font-size: 18px;
  padding: 2px 15px;
}
#english {
  background-color: #0092ad !important;
  color: white;
  padding: 2px 15px;
  font-size: 18px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #0092ad;
} */

@media only screen and (max-width: 769px) {
  h2#mainuperline {
    font-size: 20px !important;
  }
  h4#modalbodylines {
    line-height: 1.1 !important;
    font-size: 12px !important;
  }

  h3#thanks {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 426px) {
  h2#mainuperline {
    font-size: 15px !important;
  }
  h4#modalbodylines {
    line-height: 1.4 !important;
    font-size: 12px !important;
  }

  h3#thanks {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 376px) {
  h2#mainuperline {
    font-size: 12px !important;
  }
  h4#modalbodylines {
    line-height: 1.2 !important;
    font-size: 10px !important;
  }

  h3#thanks {
    font-size: 0.7rem !important;
  }
}
