div.welcomeMarquee {
  background: #0a203b;
  color: #fff;
  font-weight: bold;
  /* padding: 12px; */
  /* padding-bottom: 6px; */
  /* line-height: 1.5; */
  vertical-align: middle;
}

#brandlogosize img {
  height: 65px;
  width: 150px !important;
}

#acelogosidebar1 {
  font-size: 50px !important;
  letter-spacing: 5px;

  /* font-weight: bold !important; */
  /* font-style: italic !important; */

  background: linear-gradient(to right);
  background: linear-gradient(to right, #827244, #cbb26a, #827244);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "Trajan Pro", "Futura", system-ui, -apple-system,
    BlinkMacSystemFont;
}

#coinsContainer {
  font-size: 14px;
  gap: 10px;
}

@media only screen and (max-width: 768px) {
  #imgresponsivesize {
    gap: 30px;
  }

  #acelogosidebar1 {
    font-size: 35px !important;
    font-weight: 450 !important;
    font-style: italic !important;
  }

  #coinsContainer {
    flex-direction: column;
  }

  #jmdLogo {
    width: 120px !important;
    height: 40px !important;
  }

  #coinsContainer1 {
    margin-top: -10px !important;
    padding-top: 0px !important;
  }
}
