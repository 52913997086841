/* src/Sidebar.css */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: white;
  color: black;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.closed {
  transform: translateX(-100%);
}

li#sidebariconname {
  color: black !important;
  font-weight: bold !important;
}

.sidebar .close-btn {
  /* background-color: #444; */
  color: black;
  font-size: 30px !important;
  /* border: none; */
  /* padding: 10px; */
  cursor: pointer;
  padding-right: 10px !important;
  /* display: block; */
  /* margin-bottom: 10px; */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebariconname {
  height: 60px;
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

span.titleContainer {
  font-weight: 600;
  line-height: 20px;
  padding-top: 2px;
}

.iconContainer {
  color: rgba(0, 0, 0, 0.7);
}

.sidebariconname:hover > .iconContainer {
  color: #027a93 !important;
}

.sidebariconname:hover > span.titleContainer {
  color: #027a93 !important;
}

/* #acelogosidebar {
  font-size: 50px !important;
  letter-spacing: 5px;

  /* font-weight: bold !important; */
/* font-style: italic !important; */

/* background: linear-gradient(to right);
  background: linear-gradient(to right, #827244, #cbb26a, #827244);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "Trajan Pro", "Futura", system-ui, -apple-system;
} */

/* #mainwebsitename1 {
  font-size: 50px !important;
  font-weight: bold !important;
  font-style: italic !important;

  background: linear-gradient(to right);
  background: linear-gradient(to right, #827244, #cbb26a, #827244);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
