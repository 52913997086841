tr.placeBetRow {
  background-color: darkslateblue;
}

tr.placeBetRow td.firstColumn {
  color: white;
  font-weight: 500;
  line-height: 1.2;
  font-size: 16px;
}

ul.nav {
  background-color: #0092ad !important;
}

.bet-price-btn {
  margin-right: 10px; /* Add space between buttons */
  margin-bottom: 10px; /* Optional: Add space below buttons if they wrap to the next line */
}

.bet-price-btn:last-child {
  margin-right: 0; /* Remove right margin from the last button */
}

#sessionplusminuscolor {
  color: white;
}

td #MatchAmountInput {
  padding: 0.4rem 0.75rem;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 200px;
  text-align: left;
}

td.FontTextWhite10px {
  height: 37px;
}

td.FontTextWhite11px {
  height: 37px;
}

#tablelastcol1 {
  width: 100px;
}

a#tv_color_id1 {
  background-color: #002d5b !important;
}

#tablelastcol1 {
  max-width: 30px;
}

@media (max-width: 768px) {
  tr.placeBetRow td.firstColumn {
    font-size: 14px;
  }

  #MatchAmountInput {
    width: 70% !important;
  }

  #MatchAmountInputClear {
    width: 20% !important;
    font-size: 12px !important;
    padding: 6px;
  }

  td.FontTextWhite10px,
  td.FontTextWhite11px {
    height: 30px;
  }

  td.FontTextWhite10px.p1 {
    max-width: 46px;
  }

  td.FontTextWhite10px.p2 {
    max-width: 38px;
  }

  #tablelastcol1 {
    max-width: 16px;
  }
}
