.main-background {
  background: #fff;
  /* background: linear-gradient(360deg, #e0e0ea 0%, #5eb4ef 100%); */
  height: 100vh;
}
.MuiTypography-colorPrimary {
  color: #4a148c;
}
.MuiTypography-h5 {
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.MuiTypography-colorSecondary {
  color: #9c27b0;
}

.MuiTypography-h1 {
  font-size: 6rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.167;
  letter-spacing: -0.01562em;
}

strong,
b {
  font-weight: 700;
}

.card-header {
  background-color: #fff;
}

.form-floating > .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da !important;
  outline: 0;
  border-width: 2px;
  box-shadow: none;
}

.form-check label {
  color: #787b7d;
}

.form-check-input:checked {
  background-color: #4a148c;
  border-color: #4a148c;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.form-check-input:focus {
  background-color: #4a148c;
  border-color: #4a148c;
  outline: 0;
  box-shadow: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.form-check-input {
  width: 1.3em;
  height: 1.3em;
}

.btn {
  text-transform: uppercase;
  color: #fff;
  background-color: #9c27b0;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 8px 22px;
  font-size: 0.9375rem;
  margin: 24px 0px 16px;
}

/* .form-floating>label {
    padding: 1rem .75rem;
    transition: all .3s;
    height: 40%;
    color: #787b7d;
} */

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
  color: #4a148c;
  padding: 0rem 4px;
  background: white;
  opacity: 1;
  left: 12px;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding: 1rem 0.75rem;
}

/*# sourceMappingURL=main.css.map */

.form-floating > .form-control.client-code-input:focus {
  border-left-color: transparent !important;
}
