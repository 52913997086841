.teamName {
  background-color: "white";
  text-align: start;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  color: black;
}

.teamName p {
  font-size: 12px;
  font-weight: lighter;
}

#inplayblockboxresponsive {
  display: none;
}

#completegameteamcolor {
  color: white !important;
}

@media only screen and (max-width: 426px) {
  #completegameteamcolor {
    font-size: 13px !important;
  }

  #teamnametext {
    font-size: 13px !important;
  }
  #completegamematchnamesingal {
    font-size: 13px !important;
    padding-right: 15px;
    padding-left: 15px;
  }

  #completegamematchnamesingal11 {
    font-size: 8px !important;
  }

  #completegamematchnamesingal22 {
    font-size: 10px;
  }
  #spaninplayblockresponsive {
    display: none !important;
  }

  #inplayblockboxresponsive {
    display: block;
    border-radius: 20px !important;
    /* padding-left: 10px;
    padding-right: 10px; */
    border: none;
  }

  #spantiminginplay {
    font-size: 13px !important;
  }
}
