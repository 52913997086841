#loginbackground-2 {
  background-image: url("https://jmd15.com/assets/images/inner-bg.avif");

  background-position: center;

  background-size: cover;
}

#loginmainbutton {
  background: #01667a !important;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease; /* Smooth transition for hover effect */
}

#password1122 {
  font-size: 15px !important;
}

#form-control11 {
  border: 1px solid #ccc !important; /* Default border */
  padding: 10px !important;
  border-radius: 5px !important;
  border-radius: 15px;
}
#form-control12 {
  border: 1px solid #ccc !important; /* Default border */
  padding: 10px !important;
  border-radius: 5px !important;
  border-radius: 15px;
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: none !important;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: none !important;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: none !important;
  }
}
