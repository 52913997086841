@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

.backbuttonstyle {
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.userProfileStyle {
  background-color: #52b5e1;
  border: 1px solid #2f5c94;
}

.userProfileStyle:hover {
  background-color: #87bb33;
}

.text-center-th {
  padding: 10px;
}

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}
.mukta-regular {
  font-family: "Mukta" !important;
  font-weight: 400;
  font-style: normal;
}
.bet-card {
  height: auto;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(55, 54, 71, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
}
.bet-card .bet-level {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #26283c;
  opacity: 0.4;
}
.bet-card .bet-value {
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  color: #000;
}

@media (max-width: 768px) {
  .topledgermedia {
    padding-top: 110px !important;
  }
}
