/* src/Header.css */
.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #222;
  color: white;
}

.menu-btn {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-right: 20px;
}

.menu-btn:hover {
  background-color: #555;
}
