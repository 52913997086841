.markets .score-panel {
  position: relative;
  height: 210px;
}

.markets .score-panel .card-panel {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.markets .score-panel .card-panel.card-panel-ab {
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 120px;
}

.markets .score-panel .card-panel .card-header {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.card-panel .card-header-container {
  height: 100%;
  justify-content: space-between;
}

.markets .score-panel .card-panel .card-header.card-header-ab {
  line-height: 16px;
}

.markets .score-panel .bet-timer {
  position: absolute;
  width: 100%;
  height: 20%;
  top: 80%;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

td.playerBetBtn.disabled {
  pointer-events: none;
}

td.playerBetBtn .bet-disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.t20CreateBetDialog {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

@media (max-width: 767px) {
  .topmediaquery {
    padding-top: 120px !important;
  }
}
