* {
  font-family: "Roboto", sans-serif !important;
}

#Live_Sports {
  font-family: sans-serif !important;
  font-size: 35px !important;
  font-style: italic !important;
  /* color: #00b409 !important; */
  font-weight: bold !important;
  background: linear-gradient(to right);
  background: linear-gradient(to right, #55b0f1, #1b56c5, #00c10a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hover-container {
  position: relative;
  display: inline-block;
}

.hover-image,
.hover-link {
  position: relative;
  z-index: 1;
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(196, 196, 196);
  color: black;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 2;
}

.hover-container:hover .hover-overlay {
  opacity: 0.7;
}

@media only screen and (max-width: 542px) {
  #Live_Sports {
    font-size: 25px !important;
  }

  img#imgresponsivesize {
    height: 100px !important;
    width: 100px !important;
  }
}

@media only screen and (max-width: 400px) {
  #Live_Sports {
    font-size: 25px !important;
  }

  img#imgresponsivesize {
    height: 130px !important;
    width: 130px !important;
  }
}

@media only screen and (max-width: 379px) {
  #Live_Sports {
    font-size: 25px !important;
  }

  img#imgresponsivesize {
    height: 120px !important;
    width: 140px !important;
  }
}

@media only screen and (max-width: 534px) {
  img#scollersfifter {
    height: 350px !important;
  }

  #carouselExampleIndicators {
    height: 350px !important;
  }
}

@media only screen and (max-width: 391px) {
  img#scollersfifter {
    height: 200px !important;
  }

  #carouselExampleIndicators {
    height: 200px !important;
  }
}
